import React, { useEffect, useState } from 'react';
import { PrimeReactProvider } from 'primereact/api';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LayoutDefault from 'layouts/LayoutDefault';
import Home from 'pages/Home';
import Dashboard from 'pages/Dashboard';
import Administrator from 'pages/Administrator';
import Employer from 'pages/Employer';
import Helper from 'pages/Helper';
import HelperUpdate from 'pages/HelperUpdate';
import Job from 'pages/Job';
import Application from 'pages/Application';
import Hire from 'pages/Hire';
import HomeBanner from 'pages/HomeBanner';
import Faq from 'pages/Faq';
import Announcement from 'pages/Announcement';
import Subscription from 'pages/Subscription';
import Test from 'pages/Test';
import NotFound from 'pages/NotFound';

// import 'primereact/resources/themes/lara-light-pink/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

function App() {
  const [pageParams, setPageParams] = useState({});
  return (
    <PrimeReactProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/" element={<LayoutDefault pageParams={pageParams} />}>
            <Route path="/dashboard" element={<Dashboard setPageParams={setPageParams} />} />
            <Route path="/administrator" element={<Administrator setPageParams={setPageParams} />} />
            <Route path="/employer/:user_id?" element={<Employer setPageParams={setPageParams} />} />
            <Route path="/helper/:user_id?" element={<Helper setPageParams={setPageParams} />} />
            <Route path="/helperupdate/:helper_profile_id?" element={<HelperUpdate setPageParams={setPageParams} />} />
            <Route path="/job/:job_id?" element={<Job setPageParams={setPageParams} />} />
            <Route path="/application/:application_id?" element={<Application setPageParams={setPageParams} />} />
            <Route path="/hire/:hire_id?" element={<Hire setPageParams={setPageParams} />} />
            <Route path="/homebanner?" element={<HomeBanner setPageParams={setPageParams} />} />
            <Route path="/faq?" element={<Faq setPageParams={setPageParams} />} />
            <Route path="/announcement?" element={<Announcement setPageParams={setPageParams} />} />
            <Route path="/subscription?" element={<Subscription setPageParams={setPageParams} />} />
            <Route path="/test/" element={<Test setPageParams={setPageParams} />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </PrimeReactProvider>
  );
}

export default App;
