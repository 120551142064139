import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primeicons/primeicons.css';

import moment from 'moment-timezone';

import MessageBox from 'components/MessageBox';
import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';
import fetchUpload from 'lib/fetchUpload';
import functions from 'lib/functions';

const domain = (process.env.NODE_ENV === 'development') ?
  'http://localhost:54321/functions/v1/cmsapi/' :
  'https://rlrsahkegsezipmbvvcs.supabase.co/functions/v1/cmsapi/'

const PRODUCTS = require('jsons/products.json');

const Subscription = (props) => {
  const toast = useRef(null);
  const uploadRef = useRef(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });
  const [filters, setFilters] = useState({
    user_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    user_group: { value: null, matchMode: FilterMatchMode.CONTAINS },
    product_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    date_from: { value: null, matchMode: FilterMatchMode.CONTAINS },
    date_to: { value: null, matchMode: FilterMatchMode.CONTAINS },
    is_valid: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  useEffect(() => {
    props.setPageParams({
      alias: 'subscription',
      title: '付費訂閱'
    });
    getSubscriptions();
  }, []);

  const refreshList = () => {
    setSubscriptions(null);
    getSubscriptions();
  };

  const getSubscriptions = async () => {
    const responseList = await fetchApi('subscription/list');
    if (responseList.data?.subscriptions) {
      setSubscriptions(responseList.data.subscriptions.map((subscription) => {
        return {
          ...subscription,
          user_name: subscription.users.nickname,
          user_group: subscription.users.employer_profiles.length > 0 ? '僱主' : subscription.users.employer_profiles.length > 0 ? '外傭' : '',
          product_name: functions.getObjectValueFromArrayById(PRODUCTS.zh, subscription.sales_transactions?.product_id),
          date_from: subscription.date_from,
          date_to: subscription.date_to,
          created_at: subscription.created_at,
          is_valid: subscription.is_valid
        };
      }));
    } else {
      setSubscriptions([]);
    }
  };

  const createSubscription = () => {
    setSubscriptionDetail({
      id: null,
      content: '',
      visibled: true,
    });
    setIsEditing(false);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsUnsaved(false);
  };

  const showDetail = async (subscription_id) => {
    setSubscriptionDetail(null);
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsUnsaved(false);
    setTimeout(async () => {
      const response = await fetchApi('subscription/list', {
        id: subscription_id
      });
      if (response.state === 1 && response.data?.subscriptions?.length > 0) {
        const subscription = response.data.subscriptions[0];
        setSubscriptionDetail({
          ...subscription,
          user_name: subscription.users.employer_profiles.length > 0 ? subscription.users.employer_profiles[0].employer_name : subscription.users.helper_profiles.length > 0 ? subscription.users.helper_profiles[0].helper_name : '',
          user_group: subscription.users.employer_profiles.length > 0 ? '僱主' : subscription.users.employer_profiles.length > 0 ? '外傭' : '',
          product_name: functions.getObjectValueFromArrayById(PRODUCTS.zh, subscription.sales_transactions?.product_id),
          date_from: subscription.date_from,
          date_to: subscription.date_to,
          created_at: subscription.created_at,
          is_valid: subscription.is_valid,
          remaining: moment(subscription.date_to).diff(moment(), 'days')
        });
        setIsLoadingDetail(false);
      }
    }, 100);
  };

  const formatListFields = (field, row) => {
    switch (field) {
      case 'is_valid':
        return row.is_valid ? <Tag severity="success" value="生效" /> : <Tag severity="danger" value="過期" />;
      break;
      case 'created_at':
        return moment(row.created_at).format('YYYY年M月D日 H:mm');
      break;
      case 'date_from':
        return moment(row.date_from).format('YYYY年M月D日');
      break;
      case 'date_to':
        return moment(row.date_to).format('YYYY年M月D日');
      break;
    };
  };

  const filterElementIsValid = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterInputText = (options) => {
    return <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value)} className="p-inputtext-sm p-2" />
  };

  const onHideSidebar = () => {
    if (isSaving) {
      return false;
    }
    if (isUnsaved) {
      confirmDialog({
        message: '你還未儲存變更，確定要關閉？',
        header: '未儲存變更',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-warning',
        accept: () => {setIsOpenDetail(false)},
      });
    } else {
      setIsOpenDetail(false);
    }
  };

  const updateSubscriptionDetail = (field, value) => {
    setIsUnsaved(true);
    setSubscriptionDetail(subscriptionDetail => {
      return {
        ...subscriptionDetail,
        [field]: value,
      };
    });
  };
  
  const save = async () => {
    setIsSaving(true);
    const responseSave = await fetchApi('subscription/save', subscriptionDetail);
    if (responseSave.state === 1) {
      setSubscriptionDetail({
        ...subscriptionDetail,
        id: responseSave.data.id
      })
      toast.current.show({ severity: 'success', summary: '付費訂閱管理', detail: isEditing?'已成功儲存付費訂閱資料。':'已成功新增付費訂閱。' });
      setIsUnsaved(false);
      getSubscriptions();
    } else {
      var content = '';
      switch (responseSave.error[0]) {
        default: content = `儲存資料時出現錯誤，請確認資料無誤。`; break;
        case 'content_is_required': content = `必須輸入內容。`; break;
      }
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul><li>{content}</li></ul>,
        type: 'danger',
      });
    }
    setIsEditing(true);
    setIsSaving(false);
  };


  const reorderingSubscriptions = async (e) => {
    const {
      value,
      dragIndex,
      dropIndex
    } = e;
    // update locally
    const subscriptions = value.map((subscription, index) => {
      return {
        ...subscription,
        ordering: index+1
      };
    });
    setSubscriptions(subscriptions);
    // update remotely
    const responseReorder = await fetchApi('subscription/reordering', {
      drag: dragIndex + 1,
      drop: dropIndex + 1
    });
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-start"
          start={<TableTopTitle title="付費訂閱列表" subTitle="這裡顯示所有於後台儀表板顯示的「公告」。" />}
          end={
            <>
              <Button icon="pi pi-sync" size="small" className="w-2rem h-2rem p-0 mr-3" rounded onClick={refreshList} disabled={subscriptions===null} />
              <Button label="新增" size="small" icon="pi pi-plus" onClick={createSubscription} />
            </>
          }
        />
      </div>
      <DataTable
        value={subscriptions}
        selectionMode="single"
        onRowClick={(e) => {showDetail(e.data.id)}}
        emptyMessage={subscriptions===null?'讀取中...':'未有記錄'}
        selection={selectedSubscription}
        stripedRows
        onSelectionChange={(e) => setSelectedSubscription(e.value)} dataKey="id"
        metaKeySelection={true}
        removableSort
        paginator
        rows={15}
        rowsPerPageOptions={[15, 30, 50, 100]} 
        filterDisplay="row"
        filters={filters}
        reorderableRows
        onRowReorder={(e) => reorderingSubscriptions(e)}
      >
        <Column field="user_name" header="會員" sortable filter filterElement={filterInputText} headerStyle={{ width: 300 }}></Column>
        <Column field="user_group" header="組別" sortable filter filterElement={filterInputText} headerStyle={{ width: 200 }}></Column>
        <Column field="product_name" header="購買項目" sortable filter filterElement={filterInputText} headerStyle={{ width: 300 }}></Column>
        <Column field="date_from" header="生效日" sortable filter filterElement={filterInputText} headerStyle={{ width: 200 }} body={(row) => formatListFields('date_from', row)}></Column>
        <Column field="date_to" header="到期日" sortable filter filterElement={filterInputText} headerStyle={{ width: 200 }} body={(row) => formatListFields('date_to', row)}></Column>
        <Column field="created_at" header="建立日期" body={(row) => formatListFields('created_at', row)} headerStyle={{ width: 200 }} sortable></Column>
        <Column field="is_valid" header="過期" body={(row) => formatListFields('is_valid', row)} sortable dataType="boolean" filter filterElement={filterElementIsValid}></Column>
      </DataTable>
      <Sidebar visible={isOpenDetail} position="right" onHide={() => {onHideSidebar()}} style={{ width: 500 }}>
        <div className="mt-1">
          {isLoadingDetail || subscriptionDetail === null ?
            <div className="grid">
              <div className="col-12">
                <Skeleton className="mb-4 w-3 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2" height={160}></Skeleton>
              </div>
              <div className="col-12 flex justify-content-end gap-2">
                <Skeleton className="mb-2 w-2 h-2rem"></Skeleton>
              </div>
              <Divider />
              <div className="col-12 flex justify-content-end gap-2">
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
              </div>
            </div>
          :
            <>
              <div className="grid">
                <div className="col-12 flex align-items-center mb-4">
                  <h2 className="m-0">付費訂閱內容</h2>
                </div>
                <div className="col-8">
                  <div className="text-base text-400 mb-1"><i className="pi pi-user mr-2"></i>會員名稱</div>
                  <div className="text-xl flex flex-row align-items-center">{subscriptionDetail.users.nickname} <Tag value={subscriptionDetail.users.employer_profiles.length > 0 ? '僱主' : subscriptionDetail.users.helper_profiles.length > 0 ? '外傭' : '' } className="text-md ml-2"></Tag></div>
                </div>
                <div className="col-4 flex justify-content-end align-items-center">
                  {subscriptionDetail.users.employer_profiles.length > 0 && <Button text label="" icon="pi pi-external-link" size="small" onClick={() => {window.open(`/employer/${subscriptionDetail.users.id}`, '_blank')}} />}
                  {subscriptionDetail.users.helper_profiles.length > 0 && <Button text label="" icon="pi pi-external-link" size="small" onClick={() => {window.open(`/helper/${subscriptionDetail.users.id}`, '_blank')}} />}
                </div>
                <Divider align="left">訂閱資訊</Divider>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-star mr-2"></i>訂閱方案</div>
                  <div className="text-xl">{subscriptionDetail.product_name}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-calendar-clock mr-2"></i>生效日期</div>
                  <div className="text-xl">{moment(subscriptionDetail.date_from).format('YYYY年M月D日')} - {moment(subscriptionDetail.date_to).format('YYYY年M月D日')}</div>
                </div>
                <div className="col-12">
                  {subscriptionDetail.is_valid ?
                    <div className="flex flex-row align-items-center">
                      <Tag value="生效中" severity="success" className="text-md mr-1"></Tag>
                      <div className="text-sm">（<span className="font-bold">{subscriptionDetail.remaining}</span>日後到期）</div>
                    </div>
                  :
                    <div className="flex flex-row align-items-center">
                      <Tag value="已到期" severity="danger" className="text-md mr-1"></Tag>
                    </div>
                  }
                </div>
                <Divider align="left">付款資訊</Divider>
                <div className="col-12">
                  <Tag value={subscriptionDetail.sales_transactions.platform.toUpperCase()} className="text-md mr-1"></Tag>
                </div>
                <div className="col-12">
                  <div className="grid">
                    <div className="col-7">
                      <div className="text-base text-400 mb-1"><i className="pi pi-dollar mr-2"></i>付款金額</div>
                      <div className="flex flex-row align-items-center">
                        <div className="text-xl font-bold">{subscriptionDetail.sales_transactions.currency} {subscriptionDetail.sales_transactions.price_in_purchased_currency.toLocaleString()}</div>
                        <div className="text-md ml-4">(USD ${subscriptionDetail.sales_transactions.price.toLocaleString()})</div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="text-base text-400 mb-1">付款日期</div>
                      <div className="text-xl">{moment(subscriptionDetail.sales_transactions.created_at).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-sm"><i className="pi pi-info-circle mr-2"></i> Platform Transaction ID: {subscriptionDetail.sales_transactions.platform_id}</div>
                </div>
              </div>
              <div className="col-12 flex flex-column gap-2">
                <div className="flex align-items-center justify-content-end">
                </div>
              </div>
              <Divider />
              <div className="flex justify-content-end gap-2">
                <div className="flex gap-2">
                  <Button label="關閉" size="small" onClick={() => {onHideSidebar()}} outlined disabled={isSaving} />
                </div>
              </div>
            </>
          }
        </div>
      </Sidebar>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default Subscription;
